'use client';

import { Color, COLOR_MAP, pickAvatarColor } from '@company/common/types';
import { Avatar, AvatarRootProps } from '@company/ui/components';

interface UserAvatarProps {
  firstName: string;
  lastName: string;
  size: 'lg' | 'md' | 'sm' | 'xs' | 'x-small' | 'xx-small';
  rounded?: AvatarRootProps['rounded'];
  avatarColor?: Color | null;
  avatarUrl?: string | null;
}

export function UserAvatar({
  firstName,
  lastName,
  avatarColor,
  avatarUrl,
  size = 'xs',
  rounded = 'lg'
}: UserAvatarProps) {
  return (
    <Avatar
      size={size}
      shape={'rounded'}
      colorPalette={avatarColor ? COLOR_MAP[avatarColor].palette : pickAvatarColor(firstName)}
      fallback={`${firstName[0]}${lastName[0]}`}
      rounded={rounded}
    />
  );
}
