'use client';

import { LifecycleStatus } from '@company/common/types';
import { createSupabaseBrowserClient } from '@company/supabase/client/browser';
import { getProjectsRealtimeChannelName } from '@company/supabase/realtime';
import { Box, Flex, Stack, Text } from '@company/ui/components';
import { ChevronDownIcon, DatabaseIcon, HomeIcon, ShapesIcon } from '@company/ui/icons';
import { HelpAndSupportSection } from '@components/help-and-support/drawer';
import { UserAvatar } from '@components/user';
import { getFullName } from '@lib/user';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import { useSidebar } from '@providers/sidebar-provider';
import { usePathname } from 'next/navigation';
import React from 'react';
import { SidebarItemGroupName, SidebarItemLink } from '../utils/sidebar-item';
import { UserMenu } from './user-menu';
import { useRealtime } from '@hooks/use-realtime';

interface Project {
  id: string;
  name: string;
}

interface Agent {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  avatarUrl: string | null;
}

interface SidebarProps {
  recentProjects: Project[];
  agents: Agent[];
  isImpersonating: boolean;
}

const expandedWidth = '260px';
const collapsedWidth = '64px';

export const Sidebar = ({ recentProjects, agents, isImpersonating }: SidebarProps) => {
  const pathname = usePathname();
  const { isHidden, isExpanded, isCollapsed } = useSidebar();

  return (
    <Box
      display={isHidden ? 'none' : 'block'}
      w={isExpanded ? expandedWidth : collapsedWidth}
      h={'full'}
    >
      <Flex direction={'column'} w={'full'} h={'full'} position={'relative'} pl={3} pr={2}>
        <Box mb={6} mt={3} w={'full'} pl={0} pr={1.5}>
          <UserMenu isCollapsed={isCollapsed} isImpersonating={isImpersonating} />
        </Box>
        <Flex direction={'column'} h={'full'} w={'full'} align={'center'}>
          <Stack gap={8} w={'full'} h={'full'}>
            <Box>
              <Stack gap={0.5} w={'full'} align={'center'}>
                <SidebarItemLink
                  leftIcon={<HomeIcon />}
                  href={'/home'}
                  isActive={pathname === '/home'}
                >
                  <Text>
                    <Trans>Home</Trans>
                  </Text>
                </SidebarItemLink>
                <SidebarItemLink
                  leftIcon={<ShapesIcon />}
                  href={'/projects'}
                  isActive={pathname === '/projects'}
                >
                  <Text>
                    <Trans>Projects</Trans>
                  </Text>
                </SidebarItemLink>
              </Stack>
            </Box>
            {recentProjects.length > 0 && <ProjectsSection recentProjects={recentProjects} />}
            {agents.length > 0 && <AgentsSection agents={agents} />}
            <Box mt={'auto'} mb={4}>
              <Stack gap={0.5} w={'full'} align={'center'}>
                <HelpAndSupportSection />
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

const ProjectsSection = ({
  recentProjects: initialRecentProjects
}: {
  recentProjects: Project[];
}) => {
  const { _ } = useLingui();
  const pathname = usePathname();
  const { authUser } = useAuthUser();

  const [recentProjects, setRecentProjects] = React.useState<Project[]>(initialRecentProjects);

  useRealtime<Project & { lifecycleStatus: LifecycleStatus }>({
    channelName: getProjectsRealtimeChannelName(authUser.organization.id),
    subscription: { table: 'Project', schema: 'public' },
    processEvent: payload => {
      if (payload.eventType === 'INSERT') {
        setRecentProjects(prev => [
          {
            id: payload.new.id,
            name: payload.new.name,
            lifecycleStatus: payload.new.lifecycleStatus
          },
          ...prev
        ]);
      } else if (payload.eventType === 'UPDATE') {
        if (
          payload.new.lifecycleStatus === 'ARCHIVED' ||
          payload.new.lifecycleStatus === 'DELETED'
        ) {
          setRecentProjects(prev => prev.filter(project => project.id !== payload.new.id));
        } else {
          setRecentProjects(prev => {
            const index = prev.findIndex(project => project.id === payload.new.id);
            if (index !== -1) {
              prev[index] = {
                id: payload.new.id,
                name: payload.new.name
              };
              return [...prev];
            }
            return [
              {
                id: payload.new.id,
                name: payload.new.name,
                lifecycleStatus: payload.new.lifecycleStatus
              },
              ...prev
            ];
          });
        }
      } else if (payload.eventType === 'DELETE') {
        setRecentProjects(prev => prev.filter(project => project.id !== payload.old.id));
      }
    }
  });

  React.useEffect(() => {
    setRecentProjects(initialRecentProjects);
  }, [initialRecentProjects]);

  return (
    <Stack gap={2.5}>
      <SidebarItemGroupName name={_(msg`Recent Projects`)} rightElement={<ChevronDownIcon />} />
      <Stack gap={0.5}>
        {recentProjects.map(project => (
          <SidebarItemLink
            leftIcon={<ShapesIcon />}
            href={`/projects/${project.id}`}
            isActive={pathname.startsWith(`/projects/${project.id}`)}
            key={project.id}
          >
            <Text lineClamp={1}>{project.name}</Text>
          </SidebarItemLink>
        ))}
      </Stack>
    </Stack>
  );
};

const AgentsSection = ({ agents }: { agents: Agent[] }) => {
  const { _ } = useLingui();
  const pathname = usePathname();

  return (
    <Stack gap={2.5}>
      <SidebarItemGroupName name={_(msg`Agents`)} rightElement={<ChevronDownIcon />} />
      <Stack gap={0.5}>
        {agents.map(agent => (
          <SidebarItemLink
            leftElement={<UserAvatar {...agent} size={'xx-small'} />}
            href={`/agents/${agent.id}`}
            isActive={pathname.startsWith(`/agents/${agent.id}`)}
            key={agent.id}
          >
            <Text>{getFullName(agent)}</Text>
          </SidebarItemLink>
        ))}
      </Stack>
    </Stack>
  );
};
