'use client';

import { PLATFORM_LANGUAGES, PlatformLanguageType } from '@company/common/types';
import {
  Box,
  DisplayIf,
  Flex,
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuSeparator,
  MenuTrigger,
  MenuTriggerItem,
  Text
} from '@company/ui/components';
import { ChevronDownIcon } from '@company/ui/icons';
import { UserAvatar } from '@components/user';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import { stopImpersonatingUserAction } from '@server/actions/backoffice/impersonate-user';
import Link from 'next/link';
import React from 'react';
import { changePlatformLanguageAction, signOutAction } from './actions';

export const UserMenu: React.FC<{ isCollapsed: boolean; isImpersonating: boolean }> = ({
  isCollapsed,
  isImpersonating
}) => {
  const { authUser, isAdmin } = useAuthUser();
  const { _ } = useLingui();

  const isExpanded = !isCollapsed;

  const onChangePlatformLanguage = async (platformLanguageType: PlatformLanguageType) => {
    if (platformLanguageType === authUser.language) return;
    await changePlatformLanguageAction({ platformLanguageType });
    window.location.reload();
  };

  return (
    <Box>
      <MenuRoot>
        <MenuTrigger
          asChild
          cursor={'pointer'}
          _hover={{ bgColor: 'gray.100' }}
          px={2}
          rounded={'md'}
        >
          <Flex gap={2} align={'center'} h={'full'} w={'full'} p={1}>
            <UserAvatar {...authUser} size={'xs'} />
            <Box>
              <DisplayIf condition={isExpanded}>
                <Text fontWeight={'medium'} fontSize={'sm'} color={'gray.800'}>
                  {authUser.firstName} {authUser.lastName}
                </Text>
                <Text fontSize={'xs'} color={'gray.600'} mt={-1}>
                  {authUser.organization.name}
                </Text>
              </DisplayIf>
            </Box>
            <ChevronDownIcon fontSize={'md'} ml={'auto'} />
          </Flex>
        </MenuTrigger>

        <MenuContent minWidth="240px">
          <MenuItem value={'settings'} asChild>
            <Link href={'/settings/account/preferences'}>
              <Trans>Settings</Trans>
            </Link>
          </MenuItem>
          {isAdmin && (
            <MenuItem value={'invite-and-manage-members'} asChild>
              <Link href={'/settings/members'}>
                <Trans>Invite and manage members</Trans>
              </Link>
            </MenuItem>
          )}
          <MenuSeparator />
          <MenuRoot positioning={{ placement: 'right-start', gutter: 2 }}>
            <MenuTriggerItem value="open-platform-language-settings">
              <Flex gap={2} align={'center'}>
                <Trans>Language</Trans>
              </Flex>
            </MenuTriggerItem>
            <MenuContent>
              {Object.values(PLATFORM_LANGUAGES).map(lang => (
                <MenuItem
                  key={lang.id}
                  value={lang.id}
                  onClick={() => onChangePlatformLanguage(lang.id as PlatformLanguageType)}
                >
                  {lang.getLabel(_)}
                </MenuItem>
              ))}
            </MenuContent>
          </MenuRoot>
          {authUser.role === 'SUPER_ADMIN' && (
            <MenuItem value="change-role" asChild>
              <Link href={'/backoffice'}>
                <Flex gap={2} align={'center'}>
                  <Trans>Backoffice</Trans>
                </Flex>
              </Link>
            </MenuItem>
          )}
          <MenuSeparator />
          {isImpersonating ? (
            <MenuItem
              asChild
              value="stop-impersonating"
              onClick={() => stopImpersonatingUserAction()}
            >
              <Box>
                <Trans>Undo impersonation</Trans>
              </Box>
            </MenuItem>
          ) : (
            <MenuItem asChild value="sign-out" onClick={() => signOutAction()}>
              <Box>
                <Trans>Sign out</Trans>
              </Box>
            </MenuItem>
          )}
        </MenuContent>
      </MenuRoot>
    </Box>
  );
};
