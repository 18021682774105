import { logger } from '@company/common/logger';
import { createSupabaseBrowserClient } from '@company/supabase/client/browser';
import {
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  RealtimeChannel,
  RealtimePostgresChangesFilter,
  RealtimePostgresChangesPayload
} from '@company/supabase/types';
import { useProcessingList } from '@company/ui/hooks';
import React from 'react';

type Payload = Record<string, any>;

type Subscription = Omit<
  RealtimePostgresChangesFilter<REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL>,
  'event'
>;

interface UseRealtimeProps<TPayload extends Payload> {
  onSubscribe?: () => Promise<void>;
  channelName: string;
  subscription: Subscription;
  shouldProcessEvent?: (payload: RealtimePostgresChangesPayload<TPayload>) => boolean;
  processEvent: (payload: RealtimePostgresChangesPayload<TPayload>) => void;
  refetchData?: (lastSyncDate: Date) => Promise<void>;
  options?: {
    interval?: number;
    maxReconnectDelay?: number;
    initialReconnectDelay?: number;
  };
}

export const useRealtime = <TPayload extends Payload>({
  channelName,
  subscription,
  onSubscribe,
  processEvent,
  shouldProcessEvent,
  options,
  refetchData
}: UseRealtimeProps<TPayload>) => {
  const channelRef = React.useRef<RealtimeChannel | null>(null);
  const reconnectTimeoutRef = React.useRef<number | null>(null);

  const initialReconnectDelay = options?.initialReconnectDelay ?? 1_000;
  const maxReconnectDelay = options?.maxReconnectDelay ?? 30_000;
  const reconnectDelayRef = React.useRef(initialReconnectDelay);

  const [isSubscribed, setIsSubscribed] = React.useState(false);
  const wasDisconnectedRef = React.useRef(false);
  const lastSyncDateRef = React.useRef<Date>(new Date());
  const { addToProcessingList } = useProcessingList<RealtimePostgresChangesPayload<TPayload>>({
    meetsConditions: payload => shouldProcessEvent?.(payload) ?? true,
    onProcess: payload => processEvent(payload),
    options: {
      interval: options?.interval
    }
  });

  const subscribeChannel = () => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    const supabase = createSupabaseBrowserClient();
    channelRef.current = supabase.channel(channelName);

    // Handle connection status changes and schedule reconnect attempts if needed
    const handleConnectionStatusChange = async (status: string) => {
      logger.info(`Realtime status - "${channelName}": `, status);

      if (status === 'SUBSCRIBED') {
        setIsSubscribed(true);
        reconnectDelayRef.current = initialReconnectDelay;
        wasDisconnectedRef.current = false;
        if (onSubscribe) {
          await onSubscribe();
        }

        // Only refetch data if there was a disconnect or error
        if (refetchData && wasDisconnectedRef.current) {
          await refetchData(lastSyncDateRef.current);
        }
      } else if (['CHANNEL_ERROR', 'TIMED_OUT'].includes(status)) {
        setIsSubscribed(false);
        wasDisconnectedRef.current = true;
        reconnectTimeoutRef.current = window.setTimeout(() => {
          subscribeChannel();
          reconnectDelayRef.current = Math.min(reconnectDelayRef.current * 2, maxReconnectDelay);
        }, reconnectDelayRef.current);
      }
    };

    channelRef.current?.on(
      'postgres_changes',
      {
        event: '*',
        schema: subscription.schema,
        table: subscription.table,
        filter: subscription.filter
      },
      payload => {
        addToProcessingList(payload as RealtimePostgresChangesPayload<TPayload>);
        lastSyncDateRef.current = new Date();
      }
    );

    channelRef.current?.subscribe(handleConnectionStatusChange);
  };

  React.useEffect(() => {
    subscribeChannel();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      channelRef.current?.unsubscribe();
    };
  }, [channelName]);

  // Reconnect when browser comes back online
  React.useEffect(() => {
    const handleOnline = () => {
      logger.info(`Browser came online. Reconnecting to channel "${channelName}"`);
      if (!isSubscribed) {
        wasDisconnectedRef.current = true;
        subscribeChannel();
      }
    };

    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [channelName, isSubscribed]);
};
