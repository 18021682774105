'use client';

import { getYouTubeThumbnail } from '@company/common/lib';
import {
  Box,
  CardBody,
  CardRoot,
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
  DrawerTrigger,
  Flex,
  Image,
  Stack,
  Text
} from '@company/ui/components';
import { BookOpenTextIcon, CircleHelpIcon, MessageCircleQuestionIcon } from '@company/ui/icons';
import { SidebarItemClick } from '@components/layout';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import Link from 'next/link';
import React from 'react';

export const HelpAndSupportSection = () => {
  const { _ } = useLingui();

  const [isActive, setIsActive] = React.useState(false);

  const helpSupportLinks = React.useMemo(
    () => [
      { icon: <BookOpenTextIcon />, label: _(msg`Documentation`), href: 'https://docs.get-ai.com' },
      {
        icon: <MessageCircleQuestionIcon />,
        label: _(msg`Support Chat`),
        href: 'https://community.get-ai.com'
      }
    ],
    []
  );

  const videoTutorials = [
    {
      title: _(msg`Working with Karl (Introduction)`),
      description: _(msg`Learn how to use Karl to estimate your iTWO Projects`),
      href: 'https://www.youtube.com/watch?v=3lR3SdwlbDE'
    }
  ];

  const HelpSupportLink = ({
    icon,
    label,
    href
  }: {
    icon: React.ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <SidebarItemClick
        onClick={() => window.open(href, '_blank')}
        leftElement={icon}
        isActive={false}
      >
        <Text>{label}</Text>
      </SidebarItemClick>
    );
  };

  const VideoTutorialCard = ({
    title,
    description,
    href
  }: {
    title: string;
    description: string;
    href: string;
  }) => (
    <Link href={href} target="_blank">
      <CardRoot bgColor={'gray.50'} _hover={{ bgColor: 'gray.100' }} rounded={'xl'}>
        <CardBody p={3}>
          <Flex gap={4}>
            <Image
              src={getYouTubeThumbnail(href)}
              alt={title}
              height="70px"
              borderRadius="md"
              objectFit="cover"
            />
            <Box>
              <Text fontSize={'md'} fontWeight={'semibold'}>
                {title}
              </Text>
              <Text fontSize={'sm'} color={'GrayText'} mt={0.5}>
                {description}
              </Text>
            </Box>
          </Flex>
        </CardBody>
      </CardRoot>
    </Link>
  );

  return (
    <DrawerRoot open={isActive} onOpenChange={({ open }) => setIsActive(open)}>
      <DrawerBackdrop />
      <DrawerTrigger asChild>
        <SidebarItemClick
          leftIcon={<CircleHelpIcon />}
          onClick={() => setIsActive(prev => !prev)}
          isActive={isActive}
        >
          <Text>
            <Trans>Help & Support</Trans>
          </Text>
        </SidebarItemClick>
      </DrawerTrigger>
      <DrawerContent offset="4" rounded="xl" minW={{ base: '100%', md: '400px' }} py={1}>
        <DrawerHeader py={3} px={4}>
          <DrawerTitle>
            <Trans>Help & Support</Trans>
          </DrawerTitle>
          <DrawerCloseTrigger />
        </DrawerHeader>
        <DrawerBody py={3} px={4}>
          <Stack gap={4}>
            <Box>
              <Text fontSize={'md'} fontWeight={'medium'} mb={3}>
                <Trans>Video Tutorials</Trans>
              </Text>
              <Stack gap={2}>
                {videoTutorials.map((tutorial, index) => (
                  <VideoTutorialCard
                    key={index}
                    title={tutorial.title}
                    description={tutorial.description}
                    href={tutorial.href}
                  />
                ))}
              </Stack>
            </Box>
            <Box></Box>
          </Stack>
        </DrawerBody>
        {/* <DrawerFooter borderTopWidth={1} p={1.5}>
          <Stack gap={0} w={'full'}>
            {helpSupportLinks.map(({ icon, label, href }) => (
              <HelpSupportLink icon={icon} label={label} href={href} key={href} />
            ))}
          </Stack>
        </DrawerFooter> */}
      </DrawerContent>
    </DrawerRoot>
  );
};
