'use client';

import { Button } from '@company/ui/components';
import { Trans } from '@lingui/react/macro';
import { impersonateUserAction } from '@server/actions/backoffice/impersonate-user';
import React from 'react';

export const ImpersonateUserButton = ({ user }: { user: { id: string } }) => {
  const [isImpersonatingUser, setIsImpersonatingUser] = React.useState(false);

  const onImpersonate = async () => {
    setIsImpersonatingUser(true);
    await impersonateUserAction({ userId: user.id });
    setIsImpersonatingUser(false);
  };

  return (
    <Button
      variant="subtle"
      size="sm"
      onClick={onImpersonate}
      loading={isImpersonatingUser}
      disabled={isImpersonatingUser}
    >
      <Trans>Impersonate</Trans>
    </Button>
  );
};
