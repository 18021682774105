'use client';

import { Box, Center, DisplayIf, Flex, FlexProps, Icon, Text } from '@company/ui/components';
import { useSidebar } from '@providers/sidebar-provider';
import Link from 'next/link';
import React from 'react';

export const SidebarItemLink = ({ href, ...props }: SidebarItemProps & { href: string }) => {
  return (
    <Link href={href} style={{ width: '100%' }}>
      <SidebarItem {...props} />
    </Link>
  );
};

export const SidebarItemClick = ({
  onClick,
  ...props
}: SidebarItemProps & { onClick: () => void }) => {
  return (
    <Box onClick={onClick} w={'full'}>
      <SidebarItem {...props} />
    </Box>
  );
};

interface SidebarItemProps {
  leftElement?: React.ReactNode;
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
  rightElement?: React.ReactNode;
  isActive: boolean;
  height?: string;
  minHeight?: string;
  pl?: FlexProps['pl'];
  onClick?: () => void;
}

const SidebarItem = ({
  leftElement,
  leftIcon,
  children,
  rightElement,
  isActive,
  minHeight = '32px',
  pl
}: SidebarItemProps) => {
  const { isCollapsed } = useSidebar();
  return (
    <Box w={'full'} fontSize={'sm'} color={isActive ? undefined : 'gray.800'}>
      <Flex
        align={isCollapsed ? 'center' : 'left'}
        rounded={'lg'}
        px={2}
        pl={pl}
        role="group"
        alignItems={'center'}
        bgColor={isActive ? 'gray.200' : 'transparent'}
        _hover={{
          bgColor: isActive ? 'gray.200' : 'gray.100'
        }}
        cursor="pointer"
        minH={minHeight}
        w={isCollapsed ? 'full' : 'full'}
        gap={2}
      >
        <Center minW={'24px'} minH={'24px'}>
          {leftIcon && <SidebarIcon icon={leftIcon} />}
          {leftElement}
        </Center>
        <DisplayIf condition={!isCollapsed}>{children}</DisplayIf>
        {rightElement}
      </Flex>
    </Box>
  );
};

export const SidebarIcon = ({ icon, color }: { icon: React.ReactNode; color?: string }) => {
  return (
    <Icon boxSize={4} color={color}>
      {icon}
    </Icon>
  );
};

export const SidebarItemGroupName = ({
  name,
  leftIcon,
  rightElement
}: {
  name: string;
  leftIcon?: React.ReactNode;
  rightElement?: React.ReactNode;
}) => {
  return (
    <Box pl={3} pr={3}>
      <Flex gap={2} align={'center'} fontSize={'13px'} fontWeight={'medium'} color={'gray.700'}>
        {leftIcon && leftIcon}
        <Text>{name}</Text>
        {rightElement && <Box>{rightElement}</Box>}
      </Flex>
    </Box>
  );
};
